<template>
	<div>
        <div class="container">
            <div class="row">
                <div class="col-sm-12 mt-7 text-center">
                    <div class="page-code">404</div>
                    <div class="h4-title">PAGE NOT FOUND</div>
                    <div class="description mb-2">It seems we couldn't find the page you are looking for. Please check to make sure you've typed the URL correctly.</div>
                    <v-btn rounded text outlined large color="gray--text" @click="goHome"><b>BACK TO HOMRPAGE</b></v-btn>
                </div>
            </div>
        </div>
	</div>
	
</template>

<script>
    export default {
        methods: {
            goHome(){
                this.$router.push({name: "home"});
                // this.$router.go();
            },
        },

    }
</script>